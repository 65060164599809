<template>
  <div>
    <b-row>
      <b-col md="6">
        <slot name="filter-aside-next" />
      </b-col>
    </b-row>
    <b-row>
      <b-col :md="isLapTopView ? '12' : '6'">
        <div
          v-if="isSearchable"
          class="d-flex align-items-center justify-content-start pr-lg-4"
        >
          <div
            v-if="filterClass"
            class="mr-1"
          >
            <button-dashed
              class="m-0"
              style="min-width: 145px !important; padding: 7px 16px 7px 16px"
              @click="filterToggle = !filterToggle"
            >
              <feather-icon
                icon="LFilterIcon"
                class="lightIcon"
                size="19"
              />
              <span class="filterTitle"> {{ $t('Filter') }}</span>
              <feather-icon
                icon="LArrowDownIcon"
                size="11"
                class="defaultIconColorTwo"
              />
            </button-dashed>
          </div>
          <slot name="warning-icon-on-search" />
          <autosuggest
            ref="autocomplete"
            v-model="searchQuery"
            :class="`l-table-suggestion w-75 ` "
            :suggestions="suggestions"
            :input-props="getSearchInputProps()"
            :table-suggestion="isSuggestionTable"
            :style="autoSuggestStyle"
            :table-suggestion-configs="tableSuggestionConfigs"
            v-bind="getSuggestProps()"
            :section-configs="sectionConfigs"
            @input="fetchResults"
            @keyup.enter="reloadData"
            @selected="selectHandler"
          />
          <b-button
            class="searchButton"
            variant="success"
            @click="reloadDataAndPagination"
          >
            <feather-icon
              icon="LSearchIcon"
              size="16"
            />
          </b-button>
        </div>
        <slot name="table-top-left-side" />
      </b-col>
      <b-col
        :md="isLapTopView ? '12' : '6'"
        class="align-self-center"
      >
        <div
          class="d-flex align-items-center"
          :class="`${isMobileView ? 'mt-1' : isLapTopView ? 'justify-content-start mt-1' : 'justify-content-end'}`"
        >
          <slot name="status-filter-tab" />
          <actions-drop-down-menu
            v-if="hasAction"
            icon-name=""
            title="Actions"
            :b-drop-down-items="this.$props.moduleName === 'customers' ? actionsCustomer: (this.$props.moduleName === 'catalogs' ? actionsCatalog : this.$props.moduleName === 'inventories'?actionsInventory: null)"
            :disabled="this.$props.moduleName === 'inventories'?false:!batchSelectedLength"
            @actionHandler="actionHandler"
          />
          <slot
            name="table-top-right-side-extras-prev"
            :data="totalQuery"
          />
          <switcher-to-trash-from-trash-to-list
            :can-show-trash-list="canShowTrashList"
            :back-to-list-path="backToListPath"
            :back-uri="backUri"
            :trash-list-is-uri="trashListIsUri"
            :trash-list-path="trashListPath"
            :trash-list-path-obj="trashListPathObj"
          />
          <Can
            :i="canCreate.action"
            :a="canCreate.subject"
          >
            <div
              v-if="createPageUrl"
            >
              <button-dashed
                v-if="createBtnDashed"
                class="btnBorderRevert"
              >
                <feather-icon
                  icon="LAddButtonIcon"
                  class="cursor-pointer"
                  size="28"
                  @click="gotoNewPage(createPageUrl, $event)"
                />
              </button-dashed>
              <feather-icon
                v-else
                v-b-tooltip.noninteractive.hover
                :icon="createBtnIcon"
                :title="$t('Create')"
                class="cursor-pointer"
                size="36"
                @click="gotoNewPage(createPageUrl, $event)"
              />
            </div>

          </Can>
          <slot name="table-top-right-side-extras-next" />
        </div>
      </b-col>
    </b-row>
    <div
      :class="`d-flex my-1 ${isMobileView ? 'flex-column align-items-start' : 'align-items-center'}`"
      style="gap: 12px"
    >
      <slot name="filter-aside-prev" />
      <div class="d-flex align-content-center justify-content-between w-100">
        <slot
          name="manage-monthly-date"
          class="mr-auto"
        />
        <slot
          name="filter"
          class="ml-auto"
        />

        <!--      <button-dashed-->
        <!--        v-if="filterClass"-->
        <!--        style="background-color: #D9D9D9 !important;"-->
        <!--      >-->
        <!--        <feather-icon-->
        <!--          icon="LWarningGreyIcon"-->
        <!--          size="19"-->
        <!--        />-->
        <!--      </button-dashed>-->

      </div>
    </div>
    <b-row v-if="filterClass">
      <b-col md="12">
        <component
          :is="`${moduleName}FilterComponent`"
          :filter-toggle="filterToggle"
          :filters="filters"
          :filter-class="filterClass"
          :selected-filters="selectedFilters"
          @searchQueryClear="searchQueryClear"
          @onFilter="onFilter"
          @onRemoveSelectedFilter="reloadData"
        />
      </b-col>
    </b-row>
    <component
      :is="isMobileView ? 'b-table-responsive' : 'b-table'"
      :sort-by.sync="sortBy"
      :busy="isBusy"
      :sort-desc.sync="isSortDirDesc"
      v-bind="getTableProps()"
      @row-clicked="myRowClickHandler"
    >
      <template #empty="scope">
        <div
          v-if="isError"
          class="text-center text-danger my-2"
        >
          <b-button
            v-if="isError"
            @click="reloadData()"
          >
            {{ $t('Reload') }}
          </b-button>
        </div>
        <div
          v-else
          class="text-center text-body my-2"
        >
          {{ scope.emptyText }}
        </div>
      </template>
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template
        v-if="isMobileView && isMobileViewCustom"
        #section="data"
      >
        <slot
          name="section"
          :data="data"
        >
          {{ data.value !== null && data.value !== undefined && data.value !== '' ? data.value: "&mdash;" }}
        </slot>
      </template>
      <template
        v-for="cell in cellSlotNames"
        #[cell]="data"
      >
        <slot
          :name="cell"
          :data="data"
        >
          {{ data.value !== null && data.value !== undefined && data.value !== '' ? data.value: "&mdash;" }}
        </slot>
      </template>
      <template
        v-for="head in headSlotNames"
        #[head]="data"
      >
        <slot
          :name="head"
          :data="data"
        >
          <span
            :key="data.field.key"
            v-b-tooltip.noninteractive.hover
            :title="data.field.isSortable ? ('Sort by '+ data.label) : ''"
            :class="data.field.isSortable ? 'cursor-pointer' : ''"
            @click="data.field.isSortable && sortHandler(data.field.key, isSortDirDesc)"
          >
            {{ data.label }}
            <feather-icon
              v-if="data.field.isSortable"
              :icon="data.field.key === sortBy ? (isSortDirDesc ? 'ChevronsDownIcon' : 'ChevronsUpIcon') : ''"
            />
          </span>
        </slot>
      </template>
      <template
        v-if="isDelete"
        #head(batchdelete)=""
      >
        <b-form-checkbox
          class="mt-0"
          style="margin-top: 1px !important;"
          :checked="isAllChecked"
          :disabled="tableList.length === 0"
          @change="getAllIds"
        />
      </template>
      <template
        v-if="isDelete"
        #cell(batchdelete)="data"
      >
        <b-form-checkbox
          :checked="isChecked(data.item.id)"
          @change="addToBatchArr(data.item.id)"
        />
      </template>
      <template #table-busy>
        <slot name="table-busy">
          <div class="text-center text-danger my-2">
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
          </div>
        </slot>
      </template>
    </component>
    <!--    isPagination didnt work, then isPaginationAvailable added to help  -->
    <slot
      v-if="isPagination && isPaginationAvailable"
      name="pagination"
    >
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted-black">
              {{ $t('Showing') }}
              <b-form-select
                v-model="perPage"
                :options="perPageOptions"
                class="per-page-select"
              />
              out of {{ dataMeta.of }} records
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="total > 25"
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                  :style="getStylePrevButton()"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                  :style="getStyleNextButton()"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </slot>
  </div>
</template>
<script>

import {
  BButton,
  BCol,
  BDropdown,
  BDropdownItemButton,
  BFormCheckbox,
  BFormSelect,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import Vue from 'vue'
import tableConfig from '@/libs/tableConfig'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import { BTable as BTableResponsive } from '@core/components/b-table-responsive/table-responsive/table'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import ManageMonthlyDate from '@/views/main/orders/components/use-as-global/ManageMonthlyDate.vue'
import Autosuggest from '../AutoSuggest/Autosuggest.vue'
import props from './props'
import config from './config'
import ActionsDropDownMenu from '../Actions.vue'
import SwitcherToTrashFromTrashToList from './components/SwitcherToTrashFromTrashToList.vue'

function debounce(func, timeout) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => func(...args), timeout)
  }
}

export default {
  name: 'LTable',
  components: {
    ManageMonthlyDate,
    LTableListCollector,
    BRow,
    BCol,
    BTable,
    BButton,
    BSpinner,
    BDropdown,
    Autosuggest,
    BFormSelect,
    BPagination,
    ButtonDashed,
    BFormCheckbox,
    BTableResponsive,
    ActionsDropDownMenu,
    BDropdownItemButton,
    SwitcherToTrashFromTrashToList,
  },
  directives: { 'b-tooltip': VBTooltip },
  props: { ...props },
  data() {
    return {
      suggestions: [],
      filterToggle: false,
      batchSelected: [],
      batchSelectedLength: null,
      isMobileView: false,
      order: undefined,
      isLapTopView: false,
    }
  },
  computed: {
    headSlotNames() {
      return this.tableColumns.map(item => `head(${item.key})`)
    },
    cellSlotNames() {
      return this.tableColumns.map(item => `cell(${item.key})`)
    },
    tableList() {
      return this.tableListArray
    },
    isAllChecked() {
      if (this.tableList.length) {
        return this.tableList?.length === this.batchSelected.length
      }
      return false
    },
    isBatchDeleteArrNotEmpty() {
      return this.batchSelected.length === 0
    },
    appWindowWidth() {
      return this.$store.state.app.windowWidth
    },
    mobileWindowWidth() {
      return this.$store.state.app.mobileWindowWidth
    },
    laptopWindowWidth() {
      return this.$store.state.app.laptopWindowWidth
    },
  },
  watch: {
    batchSelected: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, _) {
        if (val) {
          this.batchSelectedLength = val.length
        }
      },
    },
    appWindowWidth(val) {
      if (!val) return

      this.getAppWindowWidth(val)
    },
    batchSelectedLength(val) {
      if (!val) return

      this.batchSelectedLength = val
    },
  },
  created() {
    this.getAppWindowWidth(this.appWindowWidth)
  },
  methods: {
    getTableProps() {
      const defaultTableProps = {
        ref: `${this.moduleName}Table`,
        items: this.list,
        responsive: true,
        fields: this.tableColumns,
        'primary-key': 'id',
        'show-empty': true,
        'empty-text': this.$t('None found'),
        striped: true,
        small: true,
      }
      if (this.rowClicked) {
        defaultTableProps.hover = true
      }
      return {
        ...defaultTableProps,
        ...this.tableProps,
      }
    },
    myRowClickHandler(data, row, el) {
      if (this.rowClicked) {
        if (typeof this.rowClicked === 'function') {
          this.rowClicked(data)
        } else {
          this.gotoNewPage({ name: this.rowClicked, path: `${data.id}`, params: { id: data.id } }, el)
        }
      }
    },
    getSuggestProps() {
      const defaultProps = {
        'render-suggestion': this.renderSuggestion,
        'get-suggestion-value': this.getSuggestionValue,
      }
      return {
        ...defaultProps,
        ...this.searchProps,
      }
    },
    getSearchInputProps() {
      const defaultInputProps = {
        id: 'table-search',
        placeholder: 'Search',
        class: 'form-control',
        name: 'table-search',
      }
      return {
        ...defaultInputProps,
        ...this.searchInputProps,
      }
    },
    renderSuggestion(suggestion) {
      return suggestion.item.name
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.cust_name_dba
    },
    getSelectOptions(params) {
      this.suggestions = []
      if (this.isAutoSuggest && params && params.length > 2) {
        this.$store.dispatch(this.endpoint, {
          ...{
            search: params,
            per_page: 50,
          },
          ...this.$props.autoSuggestAddParams,
        }).then(res => {
          const { data } = this.getBody(res)
          this.suggestions = [{ data }]
        })
      }
    },
    fetchResults(searchQuery) {
      this.search(searchQuery, this)
    },
    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions(searchQuery)
    }, 1000),
    selectHandler(suggest) {
      this.$emit('searchSelected', suggest, this)
      if (!this.isReloadOnSelectSuggestItem) return
      this.reloadData()
    },
    actionHandler(args) {
      // console.log('args', args)
      // if (args.actionType === 'exportCustomerInfo') {
      //   this.showExportCSVModal()
      //   return
      // }
      if (this.actionName && args.confirmNotification !== undefined) {
        this.action(this.actionName, this.queryParams, this.confirmNotificationOptions)
      } else {
        this[args.method](args.confirmNotificationOptions)
      }
    },
    // showExportCSVModal() {
    //   this.$refs.BatchSelectedExportModal.show()
    // },
    deleteOrTrashButtonHandler(confirmNotification) {
      this.confirmNotification(this, this.batchSelected, `${this.moduleName}/${this.trashListPath ? 'moveToTrash' : 'batchDelete'}`, confirmNotification)
        .then(() => {
          this.reloadData()
          this.$nextTick(() => {
            // eslint-disable-next-line no-unused-expressions
            this.batchSelectedLength = 0
            this.batchSelected = []
          })
        })
    },
    action(actionName, queryParams, confirmNotificationOptions) {
      queryParams = { ...{ ids: this.batchSelected }, ...queryParams }
      this.confirmNotification(this, queryParams, `${this.moduleName}/${actionName}`, confirmNotificationOptions)
        .then(() => {
          this.reloadData()
          this.$nextTick(() => {
            this.batchSelectedLength = 0
            this.batchSelected = []
          })
        })
    },

    export() {
      console.log('Export LTable')
    },
    exportDropdown() {
      console.log('SubDropdown of Export')
    },
    exportDropdownCatalog() {
      console.log('exportDropdownCatalog')
    },

    // Catalog dropdowns
    exportProductInfo() {
      console.log('exportCatalogInfo')
    },
    exportProductPrices() {
      console.log('exportCatalogAddress')
    },
    addToDraftOrder() {
      console.log('addToDraftOrder')
    },
    printCatalog() {
      console.log('printCatalog')
    },
    // createOrder() {
    //   console.log('createOrder LTable')
    // },

    exportCustomerAddress() {
      console.log('exportCustomerAddress')
    },
    exportCustomerContacts() {
      console.log('exportCustomerContacts')
    },
    // Inventory  dropdowns
    exportInventory() {
      this.$emit('exportInventory', {
        btnTitle: 'Inventory',
        actionType: 'Information Inventory',
      })
    },
    importInventory() {
      this.$emit('importInventory', {
        btnTitle: 'Inventory information',
        actionType: 'Inventory',
      })
    },
    printAssetTags() {
      this.$emit('printAssetTagsInventory', {})
    },
    addToExistingQuote(args) {
      console.log('addToExistingQuote', args)
    },
    addToNewQuote(args) {
      console.log('addToNewQuote', args)
    },
    getAllIds() {
      if (this.tableList.length === this.batchSelected.length) {
        this.batchSelected = []
      } else if (this.batchSelected.length === 0) {
        this.batchSelected.push(...this.tableList.map(customer => customer.id))
      } else {
        // eslint-disable-next-line array-callback-return,consistent-return
        const filteredArr = this.tableList.map(customer => customer.id).filter(id => {
          if (!this.batchSelected.includes(id)) {
            return id
          }
        })
        this.batchSelected.push(...filteredArr)
      }
    },
    addToBatchArr(id) {
      if (this.batchSelected.includes(id)) {
        this.batchSelected = this.batchSelected.filter(batchId => batchId !== id)
      } else {
        this.batchSelected.push(id)
      }
    },
    isChecked(id) {
      return !!this.batchSelected.find(batchId => batchId === id)
    },
    onFilter(filter) {
      console.log('this.extraParams', this.extraParams)
      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const field in filter) {
        this.extraParams[field] = filter[field]
      }
      // this.reloadData()
      this.reloadDataAndPagination()
    },
    searchQueryClear() {
      this.searchQuery = ''
      this.reloadData()
    },
    reloadData() {
      this.$refs.autocomplete.loading = true
      this.filterToggle = false
      this.refetchData()
    },
    reloadDataAndPagination() {
      this.$refs.autocomplete.loading = true
      this.filterToggle = false
      this.refetchDataAndPagination()
    },
    getAppWindowWidth(width) {
      // this.isMobileView = width < this.mobileWindowWidth
      if (width < this.mobileWindowWidth) {
        this.isMobileView = true
        this.isLapTopView = false
      } else if (width < this.laptopWindowWidth) {
        this.isMobileView = false
        this.isLapTopView = true
      } else {
        this.isMobileView = false
        this.isLapTopView = false
      }
    },
    getStylePrevButton() {
      if (this.currentPage <= 1) return 'display:none'
      return 'display:block'
    },
    getStyleNextButton() {
      if (this.currentPage >= this.total / this.perPage) return 'display:none'
      return 'display:block'
    },

    sortHandler(sortBy, order) {
      this.sortBy = sortBy
      this.isSortDirDesc = !order
    },
  },
  // eslint-disable-next-line no-shadow
  setup(props) {
    const { filterClass } = props

    const { actionsCustomer, actionsCatalog, actionsInventory } = config()
    if (filterClass && filterClass.filterComponent) {
      Vue.component(`${props.moduleName}FilterComponent`, () => filterClass.filterComponent)
    }
    return {
      ...tableConfig(props.moduleName, props.tableConfigOptions, props.filterClass), actionsCustomer, actionsCatalog, actionsInventory,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/l-table-custom.scss';

.page-item.active .page-link {
  background-color: #ADADAD;
}
</style>
